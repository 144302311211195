/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import UnstyledLink from '@/components/links/UnstyledLink';

import Button from '../../buttons/Button';

interface Item {
  text: string;
  link?: any;
  onClick?: () => void;
}

interface HeaderPopoverItemProps {
  itemText: string;
  items: Item[];
  buttons?: boolean;
  className?: string;
  buttonLink?: string;
}

export default function HeaderPopoverItem({
  itemText,
  items,
  buttons = false,
  className,
  buttonLink,
}: HeaderPopoverItemProps) {
  const { t } = useTranslation('common');
  const [isShowing, setIsShowing] = React.useState(false);

  const onMenuItemClick = () => {
    if (buttonLink) {
      window.open(buttonLink, '_blank');
    }
  };

  return (
    <Popover className={className}>
      <Popover.Button
        as='div'
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
        onClick={onMenuItemClick}
        className={clsx(
          isShowing
            ? 'text-primary-500 dark:text-white'
            : 'text-inkanText dark:text-inkanLightGray',
          'group inline-flex cursor-pointer items-center rounded-md bg-white text-base font-normal hover:text-primary-500 focus:outline-none  dark:bg-stone-900 dark:hover:text-white'
        )}
      >
        {itemText}

        <ChevronDownIcon
          className={clsx(
            isShowing
              ? 'text-primary-500 dark:text-white'
              : 'text-inkanText dark:text-inkanLightGray',
            'ml-2 h-3 w-3 group-hover:text-primary-500 dark:group-hover:text-white'
          )}
          aria-hidden='true'
        />
      </Popover.Button>

      <Transition
        as='div'
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0 absolute z-10'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
        show={isShowing}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <Popover.Panel className='absolute w-40 -translate-x-2 translate-y-4 transform rounded bg-white py-1 text-sm font-normal text-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='overflow-hidden'>
            <div className='flex flex-col'>
              {!buttons &&
                items.map((item: Item) => (
                  <UnstyledLink
                    key={item.text}
                    href={item.link()}
                    className='baseline mx-4 my-2 flex text-left text-sm font-normal text-stone-700'
                    onClick={item.onClick}
                  >
                    {t(item.text)}
                  </UnstyledLink>
                ))}
              {buttons &&
                items.map((item: Item) => (
                  <Button
                    key={item.text}
                    variant='naked'
                    className='baseline mx-4 my-2 flex w-full rounded-none text-left text-sm font-normal text-stone-700'
                    onClick={item.onClick}
                  >
                    {t(item.text)}
                  </Button>
                ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
